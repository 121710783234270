<!--
 * @Description: 客户信息服务
 * @Author: ChenXueLin
 * @Date: 2021-10-20 14:00:52
 * @LastEditTime: 2022-07-15 15:50:40
 * @LastEditors: ChenXueLin
-->
<template>
  <div class="content-view-wrapper pd-10">
    <div
      class="content-view section-wrapper"
      ref="sectionWrapper"
      v-loading="loading"
      :element-loading-background="loadingBackground"
    >
      <!-- 搜索头部 start -->
      <table-title @resize="handleTableTitleResize">
        <template v-slot:searchForm>
          <el-form class="search-list" ref="searchForm" :model="searchForm">
            <el-form-item class="search-item--1" prop="customerName">
              <el-input
                v-model="searchForm.customerName"
                placeholder="客户名称"
                title="客户名称"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="phoneCallName">
              <el-input
                v-model="searchForm.phoneCallName"
                placeholder="来去电人名称"
                title="来去电人名称"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="phoneNumber">
              <el-input
                v-model="searchForm.phoneNumber"
                placeholder="来去电号码"
                title="来去电号码"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="phoneTypeCode">
              <e6-vr-select
                v-model="searchForm.phoneTypeCode"
                :data="callTypeList"
                placeholder="来去电类型"
                title="来去电类型"
                clearable
                :props="{
                  id: 'codeValue',
                  label: 'codeName'
                }"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="serverName">
              <el-input
                v-model="searchForm.serverName"
                placeholder="服务人名称"
                title="服务人名称"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="serverRoleCode">
              <e6-vr-select
                v-model="searchForm.serverRoleCode"
                :data="userRoleList"
                placeholder="服务人角色"
                title="服务人角色"
                clearable
                :props="{
                  id: 'id',
                  label: 'name'
                }"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="serverDetails">
              <el-input
                v-model="searchForm.serverDetails"
                placeholder="服务内容"
                title="服务内容"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="firstServerCode">
              <e6-vr-select
                v-model="searchForm.firstServerCode"
                :data="firstTypeList"
                :props="{
                  id: 'codeId',
                  label: 'codeName'
                }"
                placeholder="一级服务类型"
                title="一级服务类型"
                clearable
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="secondServerCode">
              <e6-vr-select
                v-model="searchForm.secondServerCode"
                :data="secondTypeList"
                :props="{
                  id: 'codeId',
                  label: 'codeName'
                }"
                placeholder="二级服务类型"
                title="二级服务类型"
                clearable
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="thirdServerCode">
              <e6-vr-select
                v-model="searchForm.thirdServerCode"
                :data="thirdTypeList"
                placeholder="三级服务类型"
                title="三级服务类型"
                clearable
                :props="{
                  id: 'codeValue',
                  label: 'codeName'
                }"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="fourthServiceCode">
              <e6-vr-select
                v-model="searchForm.fourthServiceCode"
                :data="fourthTypeList"
                placeholder="四级服务类型"
                title="四级服务类型"
                clearable
                :props="{
                  id: 'codeValue',
                  label: 'codeName'
                }"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="closeWayCode">
              <e6-vr-select
                v-model="searchForm.closeWayCode"
                :data="closeWayList"
                placeholder="关闭方式"
                title="关闭方式"
                :props="{
                  id: 'codeValue',
                  label: 'codeName'
                }"
                clearable
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--2" prop="createTime">
              <e6-date-picker
                popper-class="no-atTheMoment"
                label="服务时间"
                ref="effectiveDatePicker"
                v-model="searchForm.createTime"
                title="服务时间"
                :picker-options="pickerOptions('searchForm.createTime')"
                :default-time="['00:00:00', '23:59:59']"
                start-placeholder="服务时间（始）"
                end-placeholder="服务时间（止）"
              ></e6-date-picker>
            </el-form-item>
            <el-form-item class="search-item--buttons">
              <el-button type="primary" @click="handleSearch">查询</el-button>
              <el-button class="reset" @click="handleReset">重置</el-button>
            </el-form-item>
          </el-form>
        </template>
        <template v-slot:operateAction>
          <i class="e6-icon-export_line" title="导出" @click="handleExport"></i>
        </template>
      </table-title>
      <!-- 搜索头部 end -->

      <!-- 表格 start -->
      <section class="table-wrapper">
        <el-table
          border
          ref="tableList"
          :height="resizeViewHeight + 'px'"
          :data="tableData"
          highlight-current-row
          class="elTable"
        >
          <el-table-column type="selection" width="40"></el-table-column>
          <el-table-column
            show-overflow-tooltip
            v-for="(column, index) in columnData"
            :key="index"
            :prop="column.fieldName"
            :label="column.fieldLabel"
            :min-width="column.width"
            :fixed="column.fixed"
            :align="column.align"
            header-align="center"
          >
          </el-table-column>
          <el-table-column fixed="right" align="center" label="操作" width="80">
            <template slot-scope="scope">
              <e6-td-operate
                :data="getOperateList()"
                @command="handleOperate($event, scope.row)"
              ></e6-td-operate>
            </template>
          </el-table-column>
        </el-table>
      </section>
      <!-- 表格 end -->

      <!-- 分页 start -->
      <section class="pagination-wrapper fixed-section">
        <el-pagination
          :page-size.sync="searchForm.pageSize"
          :current-page.sync="searchForm.pageIndex"
          :page-sizes="pageSizes"
          :layout="layout"
          :total="total"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </section>
      <!-- 分页 end -->
    </div>
  </div>
</template>

<script>
import base from "@/mixins/base";
import listPage from "@/mixins/list-page";
import listPageReszie from "@/mixins/list-page-resize";
import {
  findDownList,
  getFirstType,
  getSecondType,
  getUserRoleList,
  getCustomerServiceList,
  exportCustomerServiceList
} from "@/api";
import { printError } from "@/utils/util";
import { exportData } from "@/utils/download";
export default {
  name: "customerInfoService",
  components: {},
  mixins: [listPage, listPageReszie, base],
  data() {
    return {
      roleId: [1, 2, 3, 4, 5, 6],
      loading: false,
      total: 0,
      callTypeList: [], //来去电类型下拉框
      firstTypeList: [], //一级服务类型下拉框数据
      secondTypeList: [], //二级服务类型下拉框数据
      thirdTypeList: [], //三级服务类型下拉框
      fourthTypeList: [], //四级服务类型下拉框
      serviceResult: [], //服务结果
      closeWayList: [], //关闭方式
      userRoleList: [], //角色下拉框
      searchForm: {
        customerName: "", //客户名称
        phoneCallName: "", //来去电人名称
        phoneNumber: "", //来去电号码
        phoneTypeCode: "", //来去电类型
        serverName: "", //服务人名称
        serverRoleCode: "", //服务人角色
        serverDetails: "", //服务内容
        firstServerCode: "", //一级服务类型
        secondServerCode: "", //二级服务类型
        thirdServerCode: "", //三级服务类型
        fourthServiceCode: "", //四级服务类型
        serverResultCode: "", //服务结果
        closeWayCode: "", //关闭方式
        createTime: [], //服务时间
        beginTimeTimestamp: "", //服务开始时间
        endTimeTimestamp: "", //服务结束时间
        pageIndex: 1,
        pageSize: 20
      },
      columnData: [
        {
          fieldName: "customerName",
          display: true,
          fieldLabel: "客户名称",
          width: 160,
          disabled: false,
          fixed: true,
          align: "center"
        },
        {
          fieldName: "phoneCallName",
          display: true,
          fieldLabel: "来去电人名称",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "isEmployeeName",
          display: true,
          fieldLabel: "联系人类型",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "phoneNumber",
          display: true,
          fieldLabel: "来去电号码",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "phoneType",
          display: true,
          fieldLabel: "来去电类型",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "callDuration",
          display: true,
          fieldLabel: "通话时长",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "serverName",
          display: true,
          fieldLabel: "服务人名称",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "serverRole",
          display: true,
          fieldLabel: "服务人角色",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "isAssignStr",
          display: true,
          fieldLabel: "是否下派",
          width: 80,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "reason",
          display: true,
          fieldLabel: "未下派原因",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "serverTime",
          display: true,
          fieldLabel: "服务时间",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "serverDetails",
          display: true,
          fieldLabel: "服务内容",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "firstServer",
          display: true,
          fieldLabel: "一级服务类型",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "secondServer",
          display: true,
          fieldLabel: "二级服务类型",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "thirdServer",
          display: true,
          fieldLabel: "三级服务类型",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "fourthService",
          display: true,
          fieldLabel: "四级服务类型",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "expectSolveTimeString",
          display: true,
          fieldLabel: "期望解决时间",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "taskNum",
          display: true,
          fieldLabel: "关联单据号",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "serverResult",
          display: true,
          fieldLabel: "服务结果",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "closeWay",
          display: true,
          fieldLabel: "关闭方式",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ], //表头
      tableData: [],
      refTable: "tableList",
      queryListAPI: getCustomerServiceList
    };
  },
  computed: {},
  watch: {
    // 创建开始时间
    "searchForm.createTime": {
      immediate: true,
      handler(val) {
        if (val[0] && val[1] && val[0] > val[1]) {
          this.searchForm.createTime = this.searchForm.createTime.reverse();
        }
        this.searchForm.beginTimeTimestamp = val[0] || "";
        this.searchForm.endTimeTimestamp = val[1] || "";
      }
    },
    "searchForm.firstServerCode": {
      immediate: true,
      handler(val) {
        if (val !== "") {
          this.getSecondType(val);
        } else {
          this.firstTypeList = []; //一级服务类型下拉框数据
          this.secondTypeList = [];
          this.searchForm.firstServerCode = "";
          this.searchForm.firstServer = "";
          this.searchForm.secondServerCode = "";
          this.searchForm.secondServer = "";
        }
      }
    }
  },
  created() {
    this.initData();
    this.queryList();
  },
  mounted() {
    this.setTableScrollDOM();
  },
  activated() {
    this.setScrollRecord();
  },
  methods: {
    // 初始化数据
    async initData() {
      try {
        this.loading = true;
        let promiseList = [
          findDownList([
            "electricityType",
            "closeWay",
            "serviceResult",
            "serviceType",
            "fourthService"
          ]),
          getFirstType({ whether: 0 }),
          getUserRoleList(this.roleId)
        ];
        let [callTypeRes, firstTypeRes, roleRes] = await Promise.all(
          promiseList
        );
        //来去电类型下拉框
        this.callTypeList = this.getFreezeResponse(callTypeRes, {
          path: "data.electricityType"
        });
        //关闭方式列表
        this.closeWayList = this.getFreezeResponse(callTypeRes, {
          path: "data.closeWay"
        });
        //服务结果列表
        this.serviceResult = this.getFreezeResponse(callTypeRes, {
          path: "data.serviceResult"
        });
        //三级服务类型
        this.thirdTypeList = this.getFreezeResponse(callTypeRes, {
          path: "data.serviceType"
        });
        //四级服务类型
        this.fourthTypeList = this.getFreezeResponse(callTypeRes, {
          path: "data.fourthService"
        });
        //问题一级类型
        this.firstTypeList = this.getFreezeResponse(firstTypeRes, {
          path: "data"
        });
        this.userRoleList = this.getFreezeResponse(roleRes, {
          path: "data"
        });
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    // 格式化数据
    formatList(data) {
      data.map(item => {
        item.reason = item.firstReasonStr + "/" + item.secondReasonStr;
      });
      return data;
    },
    //获取二级服务类型
    async getSecondType() {
      try {
        let res = await getSecondType({
          firstServerCode: this.searchForm.firstServerCode
        });
        this.secondTypeList = this.getFreezeResponse(res, {
          path: "data"
        });
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    // 导出
    handleExport() {
      exportData(this, exportCustomerServiceList);
    },
    // 获取页面操作权限
    getOperateList() {
      // 页面按钮权限
      let operates = [
        {
          label: "详情",
          id: 1
        }
      ];
      return operates;
      // return this.filterOperateBtn(operates);
    },
    // 操作--更多
    handleOperate(val, row) {
      if (val.id == 1) {
        this.routerPush({
          name: "callCenter/customerInfoServiceDetail",
          params: {
            refresh: true,
            serviceId: row.serviceId
          }
        });
      }
    }
  }
};
</script>
<style lang="scss" scoped></style>
